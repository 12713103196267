import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Product } from '../models/product';
import { ProductApiResponse } from '../models/api/product.response';
import { Question } from '../models/question';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private baseUrl = 'https://api.berkleba.nl';

  private filterUrl = this.baseUrl + '/api/Product/Filter'

  private httpOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.host,
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    }
  }

  productsList: Product[] = [];
  products = new BehaviorSubject(this.productsList);

  questionsList: Question[] = [];
  questions = new BehaviorSubject(this.questionsList);

  constructor(private http: HttpClient) { }

  refreshProducts() {
    // submit with no answer id's to get everything
    this.submitFilter([]);
  }

  async submitFilter(answerIds: number[]) {
    let jsonBody = "[]";

    if (answerIds.length > 0) {
      jsonBody = JSON.stringify(answerIds);
    }

    var model = await this.http.post<ProductApiResponse>(this.filterUrl, jsonBody, this.httpOptions).toPromise();

    this.productsList = model.products
    this.products.next(this.productsList)

    this.questionsList = model.questions
    this.questions.next(this.questionsList)
  }
}
