<div>
  <!-- <div class="berkleba-nav">
    <span class="berkleba-nav-left">
      <div class="berkleba-nav-dash"></div>
      <div class="berkleba-nav-text">
        <span>
          <span class="text">Onze producten</span>
        </span>
      </div>
    </span>
    <div class="berkleba-nav-container">
      <div class="row align-items-center">
        <div class="col-md-9 col-sm-8 col-7">&nbsp;</div>
      </div>
    </div>
  </div> -->
  <div class="berkleba-container">
    <app-filter></app-filter>
    <app-products></app-products>
  </div>

</div>

<div class="loading-container" *ngIf="loading">
  <div class="row justify-content-center h-100">
    <div class="spinner-grow my-auto" role="status" style="width: 6rem; height: 6rem;">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
