<div class="container-fluid berkleba-filter-container">
  <!-- for multiple questions -->
  <div class="row berkleba-filter" *ngIf="!isSingle && !isOneProduct">
    <div class="col-12 col-sm-6 col-lg-4 berkelba-filter-table-container" *ngFor="let question of questions">
      <div class="berkleba-filter-table-title">
        <h3>
          {{ question.text }}
        </h3>
        <a *ngIf="question.answers.length > 4" class="btn" (click)="this.expendedQuestionIds.includes(question.id) ? collapseQuestion(question.id) : expandQuestion(question.id)">
          <i *ngIf="this.expendedQuestionIds.includes(question.id)" class="fas fa-chevron-up"></i>
          <i *ngIf="!this.expendedQuestionIds.includes(question.id)" class="fas fa-chevron-down"></i>
        </a>
      </div>
      <div class="row" [class]="this.expendedQuestionIds.includes(question.id) ? 'row-expanded' : ''">
        <div class="col-6" *ngFor="let answer of question.answers; let answerIndex = index">
          <div *ngIf="this.expendedQuestionIds.includes(question.id) || answerIndex < 4" class="form-check">
            <label *ngIf="answer.enabled" class="checkbox-container" for="answer_{{ answer.id }}">
              {{ answer.text }}
              <input type="checkbox" value="{{ answer.id }}" id="answer_{{ answer.id }}" [checked]="this.selectedAnswerIds.includes(answer.id)" (change)="checkboxChanged($event, answer.id)">
              <span class="checkmark"></span>
            </label>
            <label *ngIf="!answer.enabled" class="checkbox-container checkbox-container-disabled">
              {{ answer.text }}
              <input type="checkbox" value="-1" id="answer_{{ answer.id }}" disabled>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!isSingle && isOneProduct">
    <h4 class="text-center">
      Dit is het overgebleven product na jouw selectie.
    </h4>
  </div>

  <!-- for single questions -->
  <div class="row berkleba-filter" *ngIf="isSingle">
    <h1 class="text-center">
      {{ questionAnswered > 0 && question.numberOfAnswers > 1 ? question.pluralText : question.text }}
    </h1>
    <div class="col-12 col-sm-6 col-md-4" *ngFor="let answer of answersForQuestion">
      <button *ngIf="this.page == 1" type="button" class="btn btn-berkleba btn-berkleba-high" [class]="this.selectedAnswerIds.includes(answer.id) ? 'btn-berkleba-selected' : ''" (click)="answerButtonClicked(answer.id)">
        {{ answer.text }}
      </button>

      <button *ngIf="this.page == 2" type="button" class="btn btn-berkleba" [class]="this.selectedAnswerIds.includes(answer.id) ? 'btn-berkleba-selected' : ''" (click)="answerButtonClicked(answer.id)">
        {{ answer.text }}
      </button>
    </div>
  </div>

  <div class="row justify-content-between">
    <div class="col-4 berkleba-filter-navigation" [style]="{'opacity': (page > 1) ? '1' : '0'}" (click)="resetFilter()">
      <i class="fas fa-arrow-left"></i>&nbsp;<span class="d-none d-sm-inline-block">Naar begin</span>
    </div>
    <div class="col-4 berkleba-filter-navigation" *ngIf="!isSingle && questionsCount > 6">
      <a class="btn" (click)="this.showAll ? showLess() : showMore()">
        {{ this.showAll ? "Minder specificaties" : "Meer specificaties" }}
      </a>
    </div>
  </div>
</div>
